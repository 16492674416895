import React from 'react'
import { Paper, Box, Typography, Grid } from '@material-ui/core'
import LocationList from '../LocationList'
import useStyles from './style'

const datasss = [
  {
    name: 'Mall of Indonesia',
    address:
      'Lantai 1 No G5 A. Jl. Raya Boulevard Barat, Kelapa Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-4585-5762',
    mobileNumb: '+62 857-7746-4746',
  },
  {
    name: 'Puri Indah Mall',
    address:
      'Lantai 1, Blok C. NO. 134 Jl Puri Agung, Puri Indah, Kembangan Selatan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11610',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-5823-317',
    mobileNumb: '+62 858-8000-7879',
  },
  {
    name: 'Pondok Indah Plaza 6',
    address:
      'Jl. Sultan Iskandar Muda, No 21, Arteri Pondok Indah, Jakarta Selatan',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-7699-217',
    mobileNumb: '+62 816-1765-5554',
  },
  {
    name: 'Grand Indonesia',
    address:
      'Lantai 1 No G5 A. Jl. Raya Boulevard Barat, Kelapa Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-2358-1332',
    mobileNumb: '+62 815-1938-9000',
  },
  {
    name: 'Plaza Senayan',
    address:
      'Lantai 2, No. 206 C, Jl. Asia Afrika No. 8 Senayan, Kebayoran Baru, Jakarta Selatan',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-5725-416',
    mobileNumb: '+62 816-1768-0000',
  },
  {
    name: 'Mansion Apartment',
    address: 'Between Lobby Aurora & Bellavista,Jakarta Utara',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-2260-4588',
    mobileNumb: '+62 857-7000-7978',
  },
  {
    name: 'Emporium Pluit Mall',
    address:
      'Jl. Pluit Selatan Raya No.15, RT.23/RW.8, Penjaringan, Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14440',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-2260-4588',
    mobileNumb: '+62 857-0000-9555',
  },
  {
    name: 'Ashta District 8',
    address:
      'Upperground Level no 31 District 8, SCBD Lot 28, Jl. Jend. sudirman kav 52-53, RT.8/RW.3, Senayan, Kec. Kby. Baru, Jakarta, Daerah Khusus Ibukota Jakarta 12190',
    openHour: 'Monday to Sunday 10AM–10PM',
    phoneNumb: '+62 21-5011-1169',
    mobileNumb: '+62 858-9033-3330',
  },
]

const LocationListWrapper = props => {
  const classes = useStyles()
  const { dataLokasi } = props
  // const locationLength = datas.length
  return (
    <Paper className={classes.root} elevation={0}>
      <Box mb="4px">
        <Typography variant="overline" className={classes.overline}>
          Location
        </Typography>
      </Box>
      {dataLokasi.map((datas, index) => (
        <Box width={'100%'} key={`store-wrapper-${index}`}>
          <Box mb="150px">
            <Typography variant="h4" className={classes.heading}>
              {datas.namaKota}
            </Typography>
          </Box>

          <Box width="100%">
            <Grid container spacing={4} justifyContent='space-between' alignItems='flex-start'>
              {datas.store.map((data, index) => (
                <Grid key={`store-${index}`} item md={datas.store.length == 1 ? 12 : 4} sm={datas.store.length == 1 ? 12 : 6} xs={12}>
                  <LocationList
                    key={index}
                    locationData={data}
                    isLastElement={datas.store.length - 1 === index}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ))}
    </Paper>
  )
}

export default LocationListWrapper

{
  /* 
  Planning bentuk API:
      [{
          city:"Jakarta",
          locationList:[
              { ... },
          ]
          city"Bandung
      }, { ... }]

*/
}
