import { makeStyles } from '@material-ui/core/styles'

const gridMaxWidth = 325
const normalFontSize = 16
const normalLineHeight = '1.5rem'

const useStyles = makeStyles(theme => ({
  locationWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2.5rem',
      borderBottom: '1.5px solid rgba(0, 0, 0, 0.1)',
      paddingBottom: '2.5rem',
    },
  },
  removeBorderBottom: {
    border: 'none',
  },
  subHeading: {
    // maxWidth: gridMaxWidth,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '1.875rem',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      //   textAlign: "center",
    },
  },
  description: {
    [theme.breakpoints.up('md')]: {
      //   width: 485,
    },
    fontSize: normalFontSize,
    lineHeight: normalLineHeight,
    color: '#000',
    opacity: '80%',
    [theme.breakpoints.down('sm')]: {
      //   textAlign: "center",
    },
  },
  title: {
    // maxWidth: gridMaxWidth,
    [theme.breakpoints.down('sm')]: {
      //   textAlign: "center",
    },
    fontSize: normalFontSize,
    fontWeight: 600,
    lineHeight: normalLineHeight,
  },
}))

export default useStyles
