import { makeStyles } from '@material-ui/core/styles'

const appBarHeight = 100
const mainPadding = '75px 30px'
const buttonMargin = 8
const smallFont = 14
const sectionPadding = '9.375rem 7.5rem'
const useStyles = makeStyles(theme => ({
  /* Main Layout */
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    background: '#FFF',
    flexDirection: 'column',
    padding: sectionPadding,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: mainPadding,
    },
  },
  /* Typography */
  overline: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 1.5,
    fontSize: smallFont,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
    },
    textAlign: 'center',
    maxWidth: '100%',
    fontSize: 48,
    fontFamily: 'roosevelt02',
  },
}))

export default useStyles
