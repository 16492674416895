import React from 'react'
import classNames from 'classnames'
import { Box, Typography, Icon } from '@material-ui/core'
import useStyles from './style'
import PhoneNumbIcon from '../../../static/images/phoneNumbIcon.svg'
import MobileNumbIcon from '../../../static/images/mobileNumbIcon.svg'
import { PinDropOutlined, LocalOfferOutlined } from '@material-ui/icons'
import { Link } from 'gatsby'

const LocationList = props => {
  const classes = useStyles()
  const { locationData, isLastElement } = props

  return (
    <Box
      mb="50px"
      className={classNames(
        classes.locationWrapper,
        isLastElement ? classes.removeBorderBottom : undefined
      )}
    >
      <Box mb="16px">
        <Typography variant="h4" className={classes.subHeading}>
          {locationData.name}
        </Typography>
      </Box>
      <Box mb="16px">
        <Typography variant="body2" className={classes.description}>
          {locationData.address}
        </Typography>
      </Box>
      <Box mb="16px">
        <Box mb="8px">
          <Typography variant="body1" className={classes.title}>
            Opening Hours
          </Typography>
        </Box>
        <Box mb="8px">
          <Typography variant="body2">{locationData.openHour}</Typography>
        </Box>
      </Box>
      <Box mb="16px">
        <Box mb="8px">
          <Typography variant="body1" className={classes.title}>
            Enquires
          </Typography>
        </Box>
        <Box mb="8px" display="flex">
          <Box mr={1.5}>
            <img src={PhoneNumbIcon} alt="phone-number-icon" />
          </Box>

          <Typography variant="body2">{locationData.phoneNumb}</Typography>
        </Box>
        <Box mb="16px" display="flex">
          <Box mr={1.5}>
            <img src={MobileNumbIcon} alt="mobile-number-icon" />
          </Box>
          <Typography variant="body2">{locationData.mobileNumb}</Typography>
        </Box>
        <Box mb="8px" display="flex">
          {locationData.mapLocation && (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              href={locationData.mapLocation}
              target={'_blank'}
              rel={'noopener noreferer'}
            >
              <Box display={'flex'} justifyContent={'center'} mr={2}>
                <Box mr={1}>
                  <Icon>
                    <PinDropOutlined />
                  </Icon>
                </Box>
                <Typography variant="body1" className={classes.title}>
                  Map Location
                </Typography>
              </Box>
            </Link>
          )}
          {locationData.url && <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            href={locationData.url}
            rel={'noopener noreferer'}
          >
            <Box justifyContent={'center'} display={'flex'}>
              <Box mr={1}>
                <Icon>
                  <LocalOfferOutlined />
                </Icon>
              </Box>
              <Typography variant="body1" className={classes.title}>
                Pricing
              </Typography>
            </Box>
          </Link>}
        </Box>
      </Box>
    </Box>
  )
}

export default LocationList
