import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Heroes from '../components/Heroes'
import LocationListWrapper from '../components/LocationListWrapper'
import { graphql } from 'gatsby'
import { SEO } from '../components/seo'

const Stores = ({ data }) => {
  const {
    datoCmsServiceLocation: banner,
    allDatoCmsLocation: datas,
    datoCmsHomePage: footer,
  } = data

  return (
    <>
      <SEO />
      <Layout>
        <Navbar />
        <Heroes
          isStorePage
          minHeight="100vh"
          imgBackground={banner.bannerWebsize.url}
          imgResponsiveBg={banner.bannerMobilesize.url}
        />
        <LocationListWrapper dataLokasi={datas.nodes} />
        <Footer
          heading={footer.headingFooter}
          sosmed={[
            { text: footer.textFooter1, link: footer.linkFooter1 },
            { text: footer.textFooter2, link: footer.linkFooter2 },
            { text: footer.textFooter3, link: footer.linkFooter3 },
          ]}
          copyright={footer.copyright}
        />
      </Layout>
    </>
  )
}

export default Stores

export const query = graphql`
  query getStorePageQuery {
    datoCmsServiceLocation {
      gambarBanner {
        url
      }
      headingBanner
      deskripsiBanner
      bannerWebsize {
        url
      }
      bannerMobilesize {
        url
      }
    }
    allDatoCmsLocation {
      nodes {
        namaKota
        store {
          name
          address
          openHour
          phoneNumb
          mobileNumb
          mapLocation
          url
        }
      }
    }
    datoCmsHomePage {
      banner {
        url
      }
      headingBanner
      deskripsiBanner
      buttonText1
      buttonLink1
      buttonText2
      buttonLink2
      gambarAbout {
        url
      }
      titleAbout
      headingAbout
      deskripsiAbout
      titleGallery
      headingGallery
      deskripsiGallery
      gallery1 {
        url
      }
      gallery2 {
        url
      }
      gallery3 {
        url
      }
      gallery4 {
        url
      }
      gallery5 {
        url
      }
      gallery6 {
        url
      }
      footerGallery
      textButtonFooterGallery
      linkButtonFooterGallery
      titleTestimonial
      headingTestimonial
      deskripsiTestimonial
      headingFooter
      textFooter1
      linkFooter1
      textFooter2
      linkFooter2
      textFooter3
      linkFooter3
      copyright
    }
  }
`
